
export default {

  standard: {
    fontCollor:'#585755',
    position: 'fixed',
    backgroundColor: '#ffffff',
    zIndex: '1',
    top: '0',
    width: '200px',
    height: '100%',
    padding: '20pt 0',
    float: 'left',
    itemAlign: 'center'
  },

  mob: {
    position: 'fixed',
    backgroundColor: '#ffffff',
    zIndex: '1',
    width: '100vw',
    float: 'left',
    overflow: 'hidden',
    fontSize: '13pt',
    left: '0px',
    bottom: '0px',
    height: '52px',
  }
}

