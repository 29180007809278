import React from 'react'

import { createUseStyles } from 'react-jss'

import useMediaQuery from 'component/useMediaQuery'

import styles from './Nav.styles'

const useStyles = createUseStyles(styles, { name: 'Nav' })

import Navmobile from './Navmobile'

import Navstandard from './Navstandard'

const navdata = [

  {
    id: 'nav1',
    href: '#home',
    src: process.env.PUBLIC_URL + 'images/logo.jpg',
    srcmob: '',
    title: ''
  },
  {
    id: 'nav2',
    href: '#home',
    src: '',
    srcmob: 'fas fa-home',
    title: 'Početna'
  },
  {
    id: 'nav3',
    href: '#gallery',
    src: '',
    srcmob: 'far fa-images',
    title: 'Galerija'
  },
  {
    id: 'nav4',
    href: '#contact',
    src: '',
    srcmob: 'far fa-address-card',
    title: 'Kontakt'
  }

]

function Nav() {

  const matches = useMediaQuery('(min-width: 992px)')

  const classes = useStyles()

  if (matches) {

    return (

      <div className={classes.standard}>
        {navdata.map((icon) => (
          <Navstandard key={icon.id} {...icon} />
        ))}

        <div className={classes.clear} />
      </div>
    )
  }
  else {

    return (

      <div className={classes.mob}>
        {navdata.map((icon) => (
          (icon.src === '') ? <Navmobile key={icon.id} {...icon} /> : undefined
        ))}
        <div className={classes.clear} />
      </div>

    )
  }
}

export default Nav

