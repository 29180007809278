import React from 'react'

import { createUseStyles } from 'react-jss'

import Album from './Album'

import styles from './Gallery.styles'

const useStyles = createUseStyles(styles, { name: 'Gallery' })

const photo = [
  {
    id: 'p1',
    href: 'https://drive.google.com/drive/folders/1QnL2OsfuD9K0IiQxU2ytsH9CRycI08eh?usp=sharing',
    src: process.env.PUBLIC_URL + 'images/highlight_flower.jpg',
    title: 'CVET',
  },
  {
    id: 'p2',
    href: 'https://drive.google.com/drive/folders/1pzOsGXuXOHCnFet3wc-MA-XU_k4E1Ye2',
    src: process.env.PUBLIC_URL + 'images/highlight_landscape.jpg',
    title: 'PEJZAŽ'
  },
  {
    id: 'p3',
    href: 'https://drive.google.com/drive/folders/1SW3ScDTVJFRI1WSSRkgZP_brLA7NuhkW',
    src: process.env.PUBLIC_URL + 'images/highlight_family.jpg',
    title: 'JESEN'
  },
  {
    id: 'p4',
    href: 'https://drive.google.com/folderview?id=1CxvvO-Dn7cdeqr9rKjz5izmr9UeoV6HA',
    src: process.env.PUBLIC_URL + 'images/knjiga-sm.jpeg',
    srcLarge: process.env.PUBLIC_URL + 'images/knjiga-lg.jpeg',
    title: 'KNJIGA USPOMENA'

  },

  {
    id: 'p5',
    href: 'https://drive.google.com/drive/folders/1nrGzFkgl1zl177YBnuwcaVeT3bwI_3-M?usp=sharing',
    src: process.env.PUBLIC_URL + 'images/highlight_models.jpg',
    title: 'MODEL'
  },
  {
    id: 'p6',
    href: 'https://drive.google.com/drive/folders/1eHu1aq3ag9BKKyDFx1THkbHnAeBotcIB?usp=sharing',
    src: process.env.PUBLIC_URL + 'images/highlight_pregnat.jpg',
    title: 'TRUDNICA'
  },
  {
    id: 'p7',
    href: 'https://drive.google.com/drive/folders/1ShjGSvYYhJkqU2clIbO_HXn55kYz9g2x',
    src: process.env.PUBLIC_URL + 'images/novogodisnje.jpg',
    title: 'NOVOGODIŠNJE'
  }
]

const Gallery = () => {
  const classes = useStyles()

  return (

    <div className={classes.gallery}>
      {photo.map((item) => (
        <Album key={item.id} {...item} />
      ))}

      <div className={classes.clear}></div>
    </div>
  )
}

export default Gallery
