import React from 'react'

import Nav from 'Nav'
import Home from 'Home'
import Gallery from 'Gallery'
import Contact from 'Contact'

import { createUseStyles } from 'react-jss'

import useMediaQuery from 'component/useMediaQuery'

import styles from './App.styles'

const useStyles = createUseStyles(styles, { name: 'Gallery' })

function App() {
  const classes = useStyles()

  const match = useMediaQuery('(min-width: 992px)')

  return (
    <div className={classes.application}>
      <div>
        <Nav />
      </div>

      <div className={match ? classes.container : classes.containermob}>

        <div id='home'>
          <Home />
        </div>

        <div id='gallery'>
          <Gallery />
        </div>

        <div id='contact'>
          <Contact />
        </div>

      </div>

    </div>
  )
}

export default App
