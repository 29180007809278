export default {

  tabmob: {
    paddingTop: '5px',
    display: 'inline-block',
    textAlign: 'center',
    height: '100%',
    width: '33%',
  },
  a: {
    textDecoration: 'none',
  },
}
