export default {
  contact: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    background: '#ffefbe',
  },

  infoArea: {
    position: 'relative',
    float: 'left',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -5%)',
  },

  '@media (max-width: 991px)': {
    contact: {
      height: 'calc(100vh - 3em)',
    },
  },

  '@media (min-width: 992px)': {
    infoArea: {
      position: 'absolute',
      float: 'left',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },

  info: {
    textDecoration: 'none',
    color: 'inherit',
    lineHeight: '2em',
    '&:hover': {
      textDecoration: 'underline'
    },

    '& .fab, & .fas': {
      fontWeight: 'bold',
      fontSize: '14pt',
      marginRight:'6px'
    },
  },
  noHover: {
    '&:hover': {
      textDecoration: 'none',
      '& .fas': {
        cursor: 'initial'
      },
    },

  },

  social: {
    paddingLeft: '14pt'
  },

  creator: {
    color: '#aaaaaa',
    textDecoration: 'none',
    position: 'absolute',
    bottom: '15px',
    right: '15px',
    fontSize: '8pt',
    '& .far': {
      fontSize: '8pt',
      margin: [0, 2]
    }
  },
}
