
import React from 'react'

import { createUseStyles } from 'react-jss'

import classNames from 'classnames'

import styles from './Contact.styles'

const useStyles = createUseStyles(styles, { name: 'Contact' })

const Contact = () => {
  const classes = useStyles()

  const d = new Date()
  let year = d.getUTCFullYear()

  return (
    <div className={classes.contact}>
      <div className={classes.infoArea}>
        <p><i>Fotografisanje Vama bitnih trenutaka</i></p>
        <a className={classes.info} target='_blank' href='mailto:andrea@slit24.com' rel="noreferrer">
          <i className='fas fa-at'>
          </i>
          andrea@slit24.com
        </a>
        <br />
        <span className={classes.info}>
          <i className='fas fa-phone'></i>
          <a className={classes.info} href='tel:+381606073322' rel='nofollow'>+381 60 60-73-322</a>
        </span>
        <br />
        <span className={classNames(classes.info, classes.noHover)}>
          <i className='fas fa-map-marker-alt'></i>
          Bačka Palanka, Srbija
        </span>
        <br />
        <br />
        <p>Ili nas posetite na</p>
        <a className={classes.info} target='_blank' href='https://www.facebook.com/slit24.photo/' rel="noreferrer">
          <i className='fab fa-facebook'></i>
          Facebook
        </a>
        <br />
        <a className={classes.info} target='_blank' href='https://www.instagram.com/slit24.photo/' rel="noreferrer">
          <i className='fab fa-instagram'></i>
          Instagram
        </a>
        <br />
        <br />
        <br />
        <br />
        slit24 © {year}

      </div>
      <a className={classes.creator} target='_blank' href='https://www.24setup.com'
        referrerPolicy="origin"
        rel="external noreferrer" >
        made with
        <i className='far fa-heart'></i>
        by 24setup
      </a>
    </div>
  )
}
export default Contact
