import React from 'react'

import { createUseStyles } from 'react-jss'

import PropTypes from 'prop-types'

import styles from './Navmobile.styles'

const useStyles = createUseStyles(styles, { name: 'Navmobile' })

function Navmobile(props) {
  console.log(props.href)

  const classes = useStyles()

  return (

    <div className={classes.tabmob} >
      <a  href={props.href} rel='noreferrer'>
        <i className={props.srcmob} />
      </a>
    </div>
  )
}

Navmobile.propTypes = {
  href: PropTypes.string.isRequired,
  srcmob: PropTypes.string.isRequired,
  title:PropTypes.string.isRequired
}
Navmobile.defaultProps = {
  src: null
}

export default Navmobile

