export default {
  tabstand: {
    position:'relative',
    display: 'block',
    textAlign: 'center',

    width: '100%',
    fontSize: '2.5vh',
    '&:hover $underline': {
      transition: '0.45s',
      width: '80%',
    },
  },
  logo: {
    width: '80%',
  },
  a: {
    lineHeight: '3em',
    color: '#585755',
    textDecoration: 'none',
  },
  tab: {
    position: 'relative',
    textAlign: 'center',
    margin: '0 auto',
    width: '80%',
    overflow: 'hidden',
  },
  underline: {
    margin:'0 auto',
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    width: '0px',
    content: '\'.\'',
    background: '#aaa',
    height: '2px',
    borderRadius: '2px',
  },
}
