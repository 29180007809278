export default {
  home: {
    textAlign: 'center',
    position: 'relative',
    color: '#fdfdfb',
    paddingTop: '50px',
    overflow: 'hidden',
    height: '100vh',
  },
  bg: {
    position: 'absolute',
    zIndex: '-1',
    top: '-50px',
    left: '-5%',
    width: '110%',
    height: '110%',
    backgroundImage: 'url(images/bg.jpg)',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  subtitle: {
    fontSize: '1em'
  },
  a: {
    color: '#fdfdfb',
    textDecoration: 'none'
  },

  title: {
    fontSize: '3em',
    fontWeight: 'bold'

  },
  titleArea: {
    position: 'absolute',
    float: 'left',
    top: '60%',
    left: '25%',
    transform: 'translate(-50%, -50%)'
  },
  '@media (max-width: 991px)': {
    home: {
      height: 'calc(100vh - 3.5em)',
      padding: '0 0 3.5em'
    }
  },

  '@media (min-width: 992px)': {
    home: {
      paddingLeft: '200px'
    }
  },

  spanTitle: {},

  '@media(max-width: 425px)': {

    titleArea: {
      transform: 'translate(-40%, -50%)'
    }
  },

  '@media(max - width: 375px)': {

    titleArea: {
      transform: 'translate(-30%, -50%)'
    }
  },

  spanSubtitle: {}
}

