import React from 'react'

import { createUseStyles } from 'react-jss'

import PropTypes from 'prop-types'

import styles from './Navstandard.styles'

const useStyles = createUseStyles(styles, { name: 'Navstandard' })

function Navstandard(props) {
  const classes = useStyles()

  let img = <div className={classes.tab} >{props.title}</div>
  if (props.src) {
    img = <img className={classes.logo} src={props.src} alt={props.title}/>
  }

  return (
    <div className={classes.tabstand} >
      <br />
      <a className={classes.a} href={props.href}>
        {img}
      </a>
      {!props.src && (<div className={classes.underline} />)}

    </div >

  )
}

Navstandard.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
Navstandard.defaultProps = {
  srcmob: null
}

export default Navstandard

