import React from 'react'

import { createUseStyles } from 'react-jss'

import styles from './Home.styles'

const useStyles = createUseStyles(styles, { name: 'Home' })

const Home = () => {
  const classes = useStyles()
  return <div className={classes.home}>
    <div className={classes.bg}></div>
    <div className={classes.titleArea}>
      <span className={classes.subtitle}>FOTOGRAF ANDREA RAVNJAK</span>
      <br />
      <span className={classes.title}>Slit24</span>
      <br />
      <span>Uhvati današnji trenutak</span>
      <br />
      <br />
      <br />
      <span><a  className={classes.a} href='#gallery'>poseti galeriju</a></span>
      <hr/>
    </div>
  </div>
}

export default Home
