export default {

  applicaton: {
    width: '100%',
  },
  container: {
    width: 'calc(100%-200px)',
    paddingLeft: '200px'
  },
  containermob: {
    width: '100%',
    padding: '0 0 3.5em'
  },

}
