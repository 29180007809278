export default {

  item: {
    flex: '0 0 33.33333%',
    maxWidth: '33.33333%',
    '&:hover $overlay': {
      visibility: 'visible',
      opacity: '1'
    },
  },
  itemContainer: {
    position: 'relative',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    width: 'auto',
    height: 'auto'
  },
  overlay: {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.8)',
    width: '100%',
    height: '100%',
    top: '0px',
    left: '0px',
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility .250s, opacity 0.250s linear',
    cursor: 'zoom-in'
  },
  icon: {
    position: 'absolute',
    float: 'left',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fdfdfb'
  },

  '@media (max-width: 991px)': {
    item: {
      flex: '0 0 50%',
      maxWidth: '50%'
    }

  },

}

