import React from 'react'

import useMediaQuery from 'component/useMediaQuery'

import PropTypes from 'prop-types'

import { createUseStyles } from 'react-jss'

import styles from './Album.styles'

const useStyles = createUseStyles(styles, { name: 'Album' })

function Album(props) {
  const classes = useStyles()
  const matches = useMediaQuery('(min-width: 992px)')
  let imageSrc = props.src
  if (matches && props.srcLarge) {
    imageSrc = props.srcLarge
  }

  return <a target='_blank' className={classes.item} href={props.href} rel='noreferrer'>
    <div className={classes.itemContainer}>
      <img className={classes.image} src={imageSrc}></img>
      <div className={classes.overlay}>
        <div className={classes.icon}>{props.title}</div>
      </div>
    </div>

  </a>
}
Album.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  srcLarge: PropTypes.string,
  title:PropTypes.string.isRequired
}
Album.defaultProps = {
  srcLarge: null
}
export default Album
